<template>
  <v-app>
    <div class="header">
      <h2 class="text-center text-h2 pb-5">Find your next role, with Owiwi!</h2>
      <div class="overlay"></div>
      <img src="@/assets/background.jpg" style="visibility: hidden;" />
    </div>

    <v-main>
      <div class="intro-container">
        <v-container grid-list-xs>
          <div class="text-center mt-16 mb-16">
            <p>Finding and getting a job can be a challenging process - but it doesn't have to be!</p>
            <p>Owiwi is on a mission to change the way talents connect with employers, by offering a new way of finding job opportunities that are ideal to your profile! </p>
            <p>That is why we are launching a new <b>Match-Making feature</b> (open BETA)!</p>
            <p>Our goal is to provide you with recommendations for positions that match your experience, qualifications, psychometric profile and culture fit!</p>
            <p>Just fill in the form below and we'll start searching for your next role!</p>
            <p>If we don't have any suitable roles at the moment, we’ll keep your profile in our records and let you know when an opportunity that suits your skills becomes available.</p>
          </div>
          <v-stepper alt-labels :value="stepper" class="mb-16 mt-16 mobile-hide">
            <v-stepper-header>
              <v-stepper-step step="1" color="#faaa1e">
                Fill in your information
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2" color="#faaa1e">
                Complete the Owiwi Assessment to complete your profile
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3" color="#faaa1e">
                Receive Job Recommendations based on your match
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="4" color="#faaa1e">
                Apply to those positions!
              </v-stepper-step>
              
            </v-stepper-header>
          </v-stepper>
          <div class="text-center mt-16 mb-16">
            <p>In order to be able to match you with job opportunities that match your profile, we need you to provide us with some additional information!</p>
          </div>
        </v-container>
      </div>
      <div class="form-container">
        <v-container style="max-width: 660px">
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-subheader style="padding: 0">Email*</v-subheader>
                <v-text-field
                  name="email"
                  label=""
                  placeholder="Please type your Email"
                  id="email"
                  v-model="form.email"
                  :rules="emailRules"
                  hide-details="auto"
                  class="pt-0"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-subheader style="padding: 0">Linkedin Url*</v-subheader>
                <v-text-field
                  name="linkedintUrl"
                  label=""
                  placeholder="Please type your Linkedin Url"
                  id="linkedinUrl"
                  v-model="form.linkedinUrl"
                  :rules="[v => !!v || 'Item is required']"
                  hide-details="auto"
                  solo
                  class="pt-0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-subheader style="padding: 0">What is your profession?*</v-subheader>
                <v-select
                  v-model="form.profession"
                  :items="functions"
                  item-text="value"
                  item-value="id"
                  :rules="[v => !!v || 'Item is required']"
                  placeholder="Please select one"
                  label=""
                  required
                  hide-details="auto"
                  class="pt-0"
                  solo
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-subheader style="padding: 0">What Industry are you currently in?*</v-subheader>
                <v-select
                  v-model="form.industry"
                  :items="industries"
                  placeholder="Please select one"
                  item-text="value"
                  item-value="id"
                  :rules="[v => !!v || 'Item is required']"
                  label=""
                  required
                  hide-details="auto"
                  class="pt-0"
                  solo
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-subheader style="padding: 0; height: auto;">
                  <div>Please select 3 Cultural Values you look for in an employer and rank them in order of importance*</div>
                </v-subheader>
                <v-subheader style="padding: 0 0 10px 0; height: auto; cursor: pointer" @click="openDrawer"><i><u>See Definitions</u></i></v-subheader>
                <v-select
                  v-model="form.values"
                  :items="values"
                  placeholder="Please select up to 3"
                  multiple
                  item-text="value"
                  item-value="id"
                  :rules="[v => v.length > 0 || 'Item is required']"
                  label=""
                  class="pt-0"
                  required
                  hide-details="auto"
                  @input="limiter"
                  solo
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-subheader style="padding: 0">
                  Other than culture, what else is most important to you?*
                </v-subheader>
                <v-select
                  v-model="form.otherValues"
                  :items="otherValues"
                  placeholder="Please select up to 3"
                  multiple
                  item-text="value"
                  item-value="id"
                  :rules="[v => v.length > 0 || 'Item is required']"
                  label=""
                  class="pt-0"
                  required
                  hide-details="auto"
                  @input="limiter"
                  solo
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-subheader style="padding: 0">
                  Please select 3 important values you self-identify with*
                </v-subheader>
                <v-select
                  v-model="form.identifyValues"
                  :items="identifyValues"
                  placeholder="Please select up to 3"
                  multiple
                  item-text="value"
                  item-value="id"
                  :rules="[v => v.length > 0 || 'Item is required']"
                  label=""
                  class="pt-0"
                  required
                  hide-details="auto"
                  @input="limiter"
                  solo
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-subheader style="padding: 0">
                  Are you looking for Remote Work?
                </v-subheader>
                <v-btn-toggle
                  v-model="form.remote"
                >
                  <v-btn value="YES">
                    Yes
                  </v-btn>
                  <v-btn value="HYBRID">
                    Hybrid
                  </v-btn>
                  <v-btn value="NO">
                    No
                  </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col cols="6" v-if="form.remote == 'NO' || form.remote == 'HYBRID'">
                <v-subheader style="padding: 0">
                  Are you open to relocating for the right job?
                </v-subheader>
                <v-btn-toggle
                  v-model="form.relocate"
                >
                  <v-btn value="YES">
                    Yes
                  </v-btn>
                  <v-btn value="NO">
                    No
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row class="mt-16">
              <v-col cols="12">
                <v-checkbox label="Terms of Use" v-model="tou"></v-checkbox>
                <v-checkbox label="Privacy Policy" v-model="pp"></v-checkbox>
                <v-btn color="#faaa1e"  class="mt-10" @click="writeData" :disabled="checkDisabled()">Submit</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
      <div class="background-right">
        <v-container class="mt-16">
          <v-row>
            <v-col cols="12" class="text-center">
              <h1>TRUSTED BY</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col :xs="12" :md="4" class="trusted">
              <img src="@/assets/7.png">
            </v-col>
            <v-col :xs="12" :md="4" class="trusted">
              <img src="@/assets/2.png">
            </v-col>
            <v-col :xs="12" :md="4" class="trusted">
              <img src="@/assets/3.png">
            </v-col>
          </v-row>
          <v-row>
            <v-col :xs="12" :md="4" class="trusted">
              <img src="@/assets/4.png">
            </v-col>
            <v-col :xs="12" :md="4" class="trusted">
              <img src="@/assets/5.png">
            </v-col>
            <v-col :xs="12" :md="4" class="trusted">
              <img src="@/assets/6.png">
            </v-col>
          </v-row>
          <v-row class="mt-16 mb-16">
            <v-col cols="12" class="text-center">
              <h1 class="mb-16">ARE YOU AN EMPLOYER?</h1>
              <p>
                Searching for talents?<br><b>Let Owiwi help you!</b> Contact us today for a free consultation!
              </p>
              <v-btn color="#faaa1e" :href="'https://calendly.com/iliasvarth_owiwi/intro'" target="_blank" >BOOK A CALL</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>

    <v-footer>
      <v-container>
        <v-row>
          <v-col :xs="12" :md="3">
            <h3>SERVICES</h3>
            <ul>
              <li><a href="https://owiwi.co.uk/product/" target="_blank">Our product</a></li>
              <li><a href="https://owiwi.co.uk/science/" target="_blank">The science</a></li>
              <li><a href="https://owiwi.co.uk/faq/" target="_blank">FAQ</a></li>
              <li><a href="https://owiwi.co.uk/blog-page/" target="_blank">Our blog</a></li>
              <li><a href="https://owiwi.co.uk/company" target="_blank">For Business</a></li>
            </ul>
          </v-col>
          <v-col :xs="12" :md="3">
            <h3>ABOUT</h3>
            <ul>
              <li><a href="https://owiwi.co.uk/about-us/" target="_blank">About us</a></li>
              <li><a href="https://owiwi.co.uk/contact/" target="_blank">Contact us</a></li>
              <li><a href="https://owiwi.co.uk/terms-of-use/" target="_blank">Terms</a></li>
              <li><a href="https://owiwi.co.uk/privacy-policy/" target="_blank">Privacy Policy</a></li>
              <li><a href="https://owiwi.co.uk/resources" target="_blank">Resources</a></li>
              <li><a href="https://owiwi.co.uk/soft-skill-definitions/" target="_blank">Soft Skill Definitions</a></li>
            </ul>
          </v-col>
          <v-col :xs="12" :md="3">
            <h3>CONTACT</h3>
            <img src="https://c7e8f8x7.stackpathcdn.com/wp-content/uploads/2021/09/image.png" alt="">
            <p>28is Oktovriou 4, Athina 106 77<br>
              Tel.:2103640924<br>
              hello@owiwi.co.uk</p>
          </v-col>
          <v-col :xs="12" :md="3">
            <h3>CONNECT</h3>
            <p>Sign up to our newsletter and get all our latest news and exclusive content straight to your inbox!</p>
            <form id="sib-form" class="email-form" method="POST" action="https://85b5b8f9.sibforms.com/serve/MUIEANlgl4PlQqyRK40gRebvAotnauQorMOyO-rRbrH2VCauL1oLZaDzLmPJJvJ2A93WRin7xXzNRO1FCZZLh1Dk6pyR5NCbHgmSiIQtvu7qhqU-YA51q8kHJLlSma9YZg_KrcBPd80aFcIVSm67o7DRqWyUsnaQQGzNZ7DCQE9mTByUQvhITOJpcV5z0vvdH-zeZo27Y0ZBV-BW" data-type="subscription">
                  <input class="input form-control" type="text" id="EMAIL" name="EMAIL" autocomplete="off" placeholder="EMAIL" data-required="true" required />
                  <input type="submit" value="Subscribe" class="btn-subscribe">
                
            </form>
            <div class="text-center mt-5">
              <p><b>Our Partners</b></p>
              <span>The latest Job Positions from</span>
              <a href="https://uk.jooble.org/jobs-human-resources-manager" target="_blank" class="text-bold"><b>Jooble</b></a> and <a href="https://uk.jobsora.com" target="_blank" class="text-bold"><b>Jobsora</b></a>
            </div>
            <div class="text-center">
              <img style="max-height: 75px;" src="https://c7e8f8x7.stackpathcdn.com/wp-content/themes/sw_owiwi/img/espa.jpg" data-src="https://c7e8f8x7.stackpathcdn.com/wp-content/themes/sw_owiwi/img/espa.jpg">
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="d-flex justify-md-space-around">
            <h3 class="d-lg-inline">FOLLOW US</h3>
            <a href="https://twitter.com/Owiwi_HireOnce" target="_blank">
              <img src="https://c7e8f8x7.stackpathcdn.com/wp-content/themes/sw_owiwi/img/twitter.svg" alt="">
            </a>
            <a href="https://www.facebook.com/Owiwi.co.uk" target="_blank">
              <img src="https://c7e8f8x7.stackpathcdn.com/wp-content/themes/sw_owiwi/img/facebook.svg" alt="">
            </a>
            <a href="https://www.instagram.com/owiwi_hr" target="_blank">
              <img src="https://c7e8f8x7.stackpathcdn.com/wp-content/themes/sw_owiwi/img/instagram.svg" alt="">
            </a>
            <a href="https://www.linkedin.com/company/owiwi/" target="_blank">
              <img src="https://c7e8f8x7.stackpathcdn.com/wp-content/themes/sw_owiwi/img/linkedin.svg" alt="">
            </a>
          </v-col>
          <v-col cols="6" class="text-right">
            @ OWIWI. All rights reserved
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      bottom
      temporary
      width="50%">
      <div style="padding: 10px">
      <v-row>
        <v-col cols="6">
          <h2>Definitions</h2>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-icon style="cursor: pointer" @click="closeDialog">mdi-close</v-icon>
        </v-col>
      </v-row>
      <v-simple-table class="custom-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Value
              </th>
              <th class="text-left">
                Definition
              </th>
              <th class="text-left">
                Also Known As
              </th>
              <th class="text-left">
                Leaders
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Agility</td>
              <td>Employees can respond quickly and effectively to changes in the marketplace and seize new opportunities</td>
              <td>Flexible<br>Nimble<br>Fast moving</td>
              <td><img src="@/assets/nvidia.jpg" style="max-width: 100px"></td>

            </tr>
            <tr>
              <td>Collaboration</td>
              <td>Employees work well together within their team and across different parts of the organization.</td>
              <td>Demostrate teamwork<br>Identify with the company<br>Join forces</td>
              <td><img src="@/assets/starbucks.png" style="max-width: 100px"></td>
            </tr>
            <tr>
              <td>Customer</td>
              <td>Employees put customers at the center of everything they do, listening to them and prioritizing their needs.</td>
              <td>Have a customer focus<br>Deliver for clients<br>Customer-driven</td>
              <td><img src="@/assets/adidas.png" style="max-width: 100px"></td>
            </tr>
            <tr>
              <td>Diversity</td>
              <td>The company promotes a diverse and inclusive workplace where no one is disadvantaged because of their gender, race, ethnicity, sexual orientation, religion, or nationality.</td>
              <td>Inclusive<br>Welcomes everyone<br>Celebrates difference</td>
              <td><img src="@/assets/hsbc.png" style="max-width: 100px"></td>
            </tr>
            <tr>
              <td>Execution</td>
              <td>Employees are empowered to act, have the resources they need, adhere to process discipline, and are held accountable for results.</td>
              <td>Demonstrate operational excellence<br>Manage projects well<br>Take ownership</td>
              <td><img src="@/assets/spacex.png" style="max-width: 100px"></td>
            </tr>
            <tr>
              <td>Innovation</td>
              <td>The company pioneers novel products, services, technologies, or ways of working.</td>
              <td>Cutting edge<br>Leads change<br>Technologically advanced</td>
              <td><img src="@/assets/amazon.png" style="max-width: 100px"></td>
            </tr>
            <tr>
              <td>Integrity</td>
              <td>Employees consistently act in an honest and ethical manner.</td>
              <td>Do the right thing<br>Behave ethically<br>Play by the rules</td>
              <td><img src="@/assets/3m.png" style="max-width: 100px"></td>
            </tr>
            <tr>
              <td>Performance</td>
              <td>The company rewards results through compensation, informal recognition, and promotions, and deals effectively with underperforming employees.</td>
              <td>Meritocratic<br>Recognizes achievement<br>Results-driven</td>
              <td><img src="@/assets/salesforce.png" style="max-width: 100px"></td>
            </tr>
            <tr>
              <td>Respect</td>
              <td>Employees demonstrate consideration and courtesy for others, and treat each other with dignity.</td>
              <td>Treat others with dignity<br>Courteous<br>Show appreciation for one another</td>
              <td><img src="@/assets/linkedin.png" style="max-width: 100px"></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </div>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import { GoogleSpreadsheet } from 'google-spreadsheet'
export default {
  name: "App",

  data: () => ({
    stepper: 1,
    valid: false,
    drawer: false,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    form: {
      email: '',
      linkedinUrl: '',
      profession: '',
      industry: '',
      values: [],
      otherValues: [],
      identifyValues: [],
      remote: null,
      relocate: null,
      upload: false
    },
    tou: false,
    pp: false,
    functions: [
      { id: "ACCOUNTING_AND_FINANCE", value: "Accounting & Finance"},
      { id: "ADMINISTRATION_AND_MANAGEMENT", value: "Administration & Management"},
      { id: "COMMERCIAL", value: "Commercial"},
      { id: "DISTRIBUTION_AND_LOGISTICS", value: "Distribution & Logistics"},
      { id: "ECOMMERCE", value: "eCommerce"},
      { id: "ENGINEERING", value: "Engineering"},
      { id: "HUMAN_RESOURCE_MANAGEMENT", value: "Human Resource Management"},
      { id: "INFORMATION_TECHNOLOGY_AND_COMPUTING", value: "Information Technology & Computing"},
      { id: "MARKETING", value: "Marketing"},
      { id: "PROCUREMENT", value: "Procurement"},
      { id: "PRODUCTION_AND_QUALITY", value: "Production & Quality"},
      { id: "RESEARCH_AND_DEVELOPMENT", value: "Research & Development"},
      { id: "SALES", value: "Sales"},
      { id: "SERVICE", value: "Service"},
      { id: "SPORTS", value: "Sports"}
    ],
    industries: [
      {id: "AEROSPACE_AND_DEFENSE", value: "Aerospace & Defense"},
      {id: "AGRICULTURE_FORESTRY_AND_FISHING", value: "Agriculture, Forestry & Fishing"},
      {id: "APPAREL_AND_FASHION", value: "Apparel & Fashion"},
      {id: "ARTS_LEISURE_ENTERTAINMENT_AND_MEDIA", value: "Arts, Leisure, Entertainment & Media"},
      {id: "AUTOMOTIVE", value: "Automotive"},
      {id: "AVIATION_AND_TRANSPORTATION", value: "Aviation & Transportation"},
      {id: "CHEMICALS_AND_SPECIALTY_MATERIALS", value: "Chemicals & Specialty Materials"},
      {id: "CONSULTING_SERVICES", value: "Consulting Services"},
      {id: "CONSUMER_PRODUCTS", value: "Consumer Products"},
      {id: "COSMETICS", value: "Cosmetics"},
      {id: "EDUCATIONAL_SERVICES", value: "Educational Services"},
      {id: "ENERGY_OIL_AND_GAS", value: "Energy, Oil & Gas"},
      {id: "ENGINEERING_AND_CONSTRUCTION_INDUSTRY", value: "Engineering & Construction Industry"},
      {id: "ENVIRONMENTAL_SERVICES", value: "Environmental Services"},
      {id: "FINANCIAL_SERVICES_AND_BANKING", value: "Financial Services & Banking"},
      {id: "FOOD_AND_BEVERAGES", value: "Food & Beverages"},
      {id: "GAMBLING", value: "Gambling"},
      {id: "HEALTHCARE", value: "Healthcare"},
      {id: "HOTELS_AND_ACCOMMODATION", value: "Hotels & Accommodation"},
      {id: "INDUSTRIAL_PRODUCTS_AND_SERVICES", value: "Industrial Products & Services"},
      {id: "INFORMATION_TECHNOLOGY_AND_IOT", value: "​Information Technology & IOT"},
      {id: "INSURANCE", value: "Insurance"},
      {id: "INVESTMENT_MANAGEMENT", value: "Investment Management"},
      {id: "LEGAL_SERVICES", value: "Legal Services"},
      {id: "MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES", value: "Management of Companies & Enterprises"},
      {id: "MANUFACTURING", value: "Manufacturing"},
      {id: "MINING_QUARRYING", value: "Mining, Quarrying"},
      {id: "PHARMACEUTICALS", value: "Pharmaceuticals"},
      {id: "POWER_AND_UTILITIES", value: "Power & Utilities"},
      {id: "PUBLIC_RELATIONS_PR_AND_EVENTS", value: "Public Relations, PR & Events"},
      {id: "PUBLIC_SECTOR_ADMINISTRATION_AND_GOVERNMENT", value: "Public Sector, Administration & Government"},
      {id: "REAL_ESTATE_AND_PROPERTY_MANAGEMENT", value: "Real Estate & Property Management"},
      {id: "RESEARCH_SERVICES", value: "Research Services"},
      {id: "RESTAURANTS", value: "Restaurants"},
      {id: "RETAIL_WHOLESALE_AND_DISTRIBUTION", value: "Retail, Wholesale & Distribution"},
      {id: "SHIPPING_AND_PORTS", value: "Shipping & Ports"},
      {id: "SPORTS", value: "Sports"},
      {id: "STAFFING_AND_RECRUITING", value: "Staffing & Recruiting"},
      {id: "TELECOMMUNICATIONS", value: "Telecommunications"},
      {id: "TOBACCO", value: "Tobacco"},
      {id: "WATER", value: "Water"},
      {id: "OTHER", value: "Other"},
    ],
    values: [
      {id:'AGILITY', value: 'Agility'}, 
      {id:'COLLABORATION', value: 'Collaboration'},
      {id:'CUSTOMER_CENTRIC', value: 'Customer Centric'},
      {id:'DIVERSITY', value: 'Diversity'},
      {id:'EXECUTION', value: 'Execution'},
      {id:'INNOVATION', value: 'Innovation'},
      {id:'INTEGRITY', value: 'Integrity'},
      {id:'PERFORMANCE', value: 'Performance'},
      {id:'RESPECT', value: 'Respect'}
    ],
    otherValues: [
      { id: 'PEOPLE_ENVIRONMENT', value: 'People & Environment'},
      { id: 'COMPENSATION', value: 'Compensation'},
      { id: 'WORK_LIFE_BALANCE', value: 'Work Life Balance'},
      { id: 'STABILITY', value: 'Stability'},
      { id: 'BRAND_NAME_REPUTATION', value: 'Brand Name / Reputation'},
      { id: 'RESPONSIBILITIES', value: 'Responsibilities'},
      { id: 'BENEFITS', value: 'Benefits'},
      { id: 'CAREER_PROGRESSION', value: 'Career Progression'},
      { id: 'JOB_TITLE', value: 'Job Title'},
      { id: 'MISSION', value: 'Mission'},
      { id: 'STRUCTURE', value: 'Structure'},
      { id: 'LEADERSHIP', value: 'Leadership'},
      { id: 'LEARNING', value: 'Learning'}
    ],
    identifyValues: [
      { id: 'INTEGRITY', value: 'Integrity' },
      { id: 'BOLDNESS', value: 'Boldness' },
      { id: 'HONESTY', value: 'Honesty' },
      { id: 'TRUST', value: 'Trust' },
      { id: 'ACCOUNTABILITY', value: 'Accountability' },
      { id: 'COMMITMENT', value: 'Commitment to Customers' },
      { id: 'PASSION', value: 'Passion' },
      { id: 'FUN', value: 'Fun' },
      { id: 'HUMILITY', value: 'Humility' },
      { id: 'CONTINUOUS_LEARNING', value: 'Continuous Learning' },
      { id: 'OWNERSHIP', value: 'Ownership' },
      { id: 'CONSTANT_IMPROVEMENT', value: 'Constant Improvement' },
      { id: 'LEADERSHIP', value: 'Leadership' },
      { id: 'DIVERSITY', value: 'Diversity' },
      { id: 'INNOVATION', value: 'Innovation' },
      { id: 'QUALITY', value: 'Quality' },
      { id: 'TEAMWORK', value: 'Teamwork' },
      { id: 'SIMPLICITY', value: 'Simplicity' }
    ]
  }),
  methods: {
    closeDialog() {
      this.drawer = false
    },
    checkDisabled() {
      if (!this.valid || !this.tou || !this.pp) {
        return true
      }
      return false;
    },
    openDrawer() {
      this.drawer = true
    },
    limiter(e) {
      if(e.length > 3) {
        e.pop()
      }
    },
    async writeData() {
      const doc = new GoogleSpreadsheet('1pQNfcDwWQOJzF2RWlk68j3jFjX8U1OXB4ONSGzqRs18');

      await doc.useServiceAccountAuth({
        client_email: 'matchmaking@glass-hydra-328009.iam.gserviceaccount.com',
        private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDqpnZorxj2/Kpy\ns9mahRdXqxFHwMPxCHPyFVbKv+krMp2H2aVXhr63SXLNkbu4aV6xgPqeNuplIcmW\nvv3OThEX9vV7F+qmxJGtjS/tWLbQGoR1rwz19BIeaBk2mwVB+pqt+pgxqDj8GEyA\nkOOHUxNiRvP9WUJ/ULcYMLsNDwSGbgdLNEnaeAy9XookZjE3Um+8BdX4hsCq7ag4\numcsp8k/N9I+4TwwFUEqHevKWnOkU9MDcxO4dLr13Z3HJhb2osUMUgyxhKQ164Eq\nWp+mzXyqeglnztspTDe59fr4lVu0JGhQSOsbc60r1zhk/Z6NHQWDLhmF9TJb8cuu\nniTdBMlVAgMBAAECggEAJf0BGn2Oex34sk0vo3Z8ivLk/0rT7gfCTaTqwjRDmXy+\nSCoSPJNR+PZpIaLAU2Crtez9pfOaCvlnW4JcMLQuzERtBZqfoJQeSYyb/77nCdXh\nL/RBEuiKrmWH/3ElbsMVm6kATrm/UADXEdFjJJtsffKiuZdjzpzQp5fsEHcn8mM6\nYFLWAT6MXxUz46sc9qYJWfw4DY1Xy58OpDVEtF5E+Qzo+vakLdfloTc0Vfef1oD6\ntZYda+iBcVZKpRxjL0Ppz4WOQmYcHyNM2PkfdaJm4mjYNcZpPsY+9AdQWCWAbf16\neQSbh2T44fyIGl+oDvPEWswMxAbLMnT/xJpAO5owQQKBgQD9O/3Kta34N2ViLqwH\nf7NcT9sR7wcSqBFNzOmS6nmy24MBPQT6hJvUp/EPTizzJZ1m/v2xzhqQpce7ghOB\nShBPqp5z9gBkMhmvIXY3QC203R4Zfxeehq61Mmwd/Uiad83Y7p0kHVKlMjwDlu0d\n/xPWXirfqdm8vDagxbsSTF7auQKBgQDtNoM3YnKZxJ7L9lSRbQqK8/iHrIX4A176\nEihYGom+AA9L3vrrhUDhT3ALgbpqS0Bn5ufe2cD3jQoocvCCL9nmdb5EPJftFJQZ\n1WdExPf7B+aQDpT/nE5T6DzipAH95/z8MAmTEZzNUgaryIVSBWDJQwSvK058NHPW\n21OWETFlfQKBgQDeBl85/5iMbGVjc/A4kuo7IXi9fIkC/oBNaj/QI+yXuIZf65QV\n4XaPOL/N34jhljUvPJpJ7p6z++XmrOFVFg6/CqTqnuEw+Aw01G3Fe2uXi9b8G8Yl\no4hCLfdFQ/EIHcpJTMxSAbOYSzb7HrS+/An0hZOpjk3tgEeVJkgifvsbYQKBgQDC\n5aUN3k82U7v87JkzGxosbKNoJbG0wmBIqp6HNNazSy1GMyCKXeOeuzvd2GqnZsi2\nFZuxF8xaGIGeFkHRVSt9FISNYBObcOr2BsZgVE1C7YzcE38MPQqGGivcMtCIIxFS\nys0zDqd8csaUYZ5Yc56//dZdbgGAP0Bl6bJVfC7PqQKBgQCnDhartJFUE2cEFmjQ\nHmgka9BqJAfza2DX7H7K8cScPujT3K+UA1k7GYJwCKWggzY+JG2/pEJ5KFRnOkzk\nST3cn8r/K7e/FJ6gLTVKdyR4oNlcyHQrFrd5rhAX3Ei7Ntpiz/GZZWZk4gdotR4b\n/Q4Mx9CnzZobEkDV4GAtBBL3qQ==\n-----END PRIVATE KEY-----\n",
      });

      await doc.loadInfo();

      const sheet = doc.sheetsByIndex[0];

      const data = {
        email: this.form.email,
        linkedinUrl: this.form.linkedinUrl,
        profession: this.form.profession,
        industry: this.form.industry,
        values: this.form.values.join(','),
        otherValues: this.form.otherValues.join(','),
        identifyValues: this.form.identifyValues.join(','),
        remote: this.form.remote,
        relocate: this.form.relocate
      }

      const result = await sheet.addRow(data);

      console.log(result)


      // const sheet = await doc.addSheet({ headerValues: ['name', 'email'] });
    }
  },
  mounted() {
    setInterval(() => {
      if (this.stepper == 4) {
        this.stepper = 1
      } else {
        this.stepper += 1
      }
    }, 3000);
  }
};
</script>

<style lang="scss">
  .custom-table{
    tr {
      td {
        padding-bottom: 1em !important
      }
    }
  }
  @media screen and (max-width: 960px) {
    .mobile-hide{
      display: none !important;
    }
  }
  @media screen and (max-width: 800px) {
    .header{
      h2 {
        font-size: 26px !important;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .header{
      background-image: unset !important;
      .overlay {
        display: none;
      }
      h2.text-h2 {
        font-size: 32px !important;
        font-weight: 700 !important;
        color: black !important;
      }
    }
    .intro-container, .form-container, .background-right {
      background-image: none !important;
    }
  }
  .v-input--checkbox {
    .v-messages{
      display: none !important;
    }
  }
  .intro-container {
    padding-top: 85px;
    padding-bottom: 85px;
    background-image: url('https://c7e8f8x7.stackpathcdn.com/wp-content/themes/sw_owiwi/img/female-pirate-background-1.png');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 30%;
  }
  .form-container {
    padding-top: 85px;
    padding-bottom: 85px;
    background-image: url('https://c7e8f8x7.stackpathcdn.com/wp-content/themes/sw_owiwi/img/male-pirate-background-1.png');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 30%;
  }
  .background-right {
    background-image: url('https://c7e8f8x7.stackpathcdn.com/wp-content/themes/sw_owiwi/img/male-pirate-background-2.png');
    background-size: 30%;
    background-position: right top;
    background-size: auto 50%;
    background-repeat: no-repeat;
  }
  .v-input__slot {
    box-shadow: none !important;
  }
  .v-application--wrap {
    background-color: #f5f8fa;
  }

  .v-sheet.v-stepper:not(.v-sheet--outlined) {
    box-shadow: none !important;
    background-color: rgba(0,0,0,0) !important
  }

  .v-stepper__label {
    text-align: center !important;
  }

  .v-stepper__step {
    padding: 14px !important;
  }

  .trusted {
    text-align: center;
    img {
      max-width: 200px;
      margin-bottom: 60px;
    }
  }

  .header{
    background-image: url('~@/assets/background.jpg');
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    .overlay {
      background-color: black;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: .5;
    }
    h2 {
      position: absolute;
      margin: 0 auto;
      width: 100%;
      top: 40%;
      color: white;
      font-weight: 400 !important;
      z-index: 2;
    }
    img {
      max-width: 100%;
    }
  }
  .v-footer {
    font-size: 16px !important;
    font-weight: 300 !important;
    background-color: #454b60 !important;
    color: rgba(255,255,255,.8) !important;
    h3{
      color: #faaa1e !important;
      margin-bottom: 2em;
    }
    ul {
      list-style-type: none;
      padding-left: 0 !important
    }
    a{
      text-decoration: none;
      color: rgba(255,255,255,.8) !important;
    }
    img{
      max-width: 100%;
      border-radius: 5px;
    }
    .email-form {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #fff;
    }
    .form-control {
      height: auto;
      width: 60%;
      padding: 1em 1.2em;
      border: 0;
      border-radius: 8px 0 0 8px;
      color: #fff;
      background: 0 0;
    }
    .btn-subscribe {
      width: 40%;
      padding-left: 0;
      padding-right: 0;
      border-radius: 0 8px 8px 0;
      background-color: #faaa1e;
      border-color: #faaa1e;
      text-transform: uppercase;
      font-weight: 700;
      color: white !important;
    }
  }
</style>